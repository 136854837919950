import {Link} from 'gatsby'
import React, {useState} from 'react'

function HeaderNew () {
    const [isExpanded, toggleExpansion] = useState(false)

    return (
        <nav className="flex items-center justify-between flex-wrap bg-black p-6">
            <div className="flex w-1/2 h-auto items-center flex-shrink-0 text-white mr-6">
                <Link to='/'>
                    <img 
                        src="https://documents.mgstech.net/logo/MGS-TECH-logo-3.png" 
                        alt="MGS Tech Innovation Technologies"
                    />
                </Link>
            </div>

            <div className="block xl:hidden">
                <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center px-3 py-2 border-none text-white">
                    <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
            </div>

            <div className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow xl:flex xl:items-center xl:w-auto`}>
                <div className="absolute bg-black p-4 text-base xl:flex-grow right-0">
                    <Link to='/instructors' className="block mt-4 xl:inline-block xl:mt-0 text-white mr-4">
                        Instructors
                    </Link>

                    <Link to='/training' className="block mt-4 xl:inline-block xl:mt-0 text-white mr-4">
                        Training
                    </Link>

                    <Link to='/auto-service' className="block mt-4 xl:inline-block xl:mt-0 text-white mr-4">
                        Auto Service
                    </Link>

                    <Link to='/auto-claims' className="block mt-4 xl:inline-block xl:mt-0 text-white mr-4">
                        Auto Claims
                    </Link>

                    <Link to='/technical-articles/' className="block mt-4 xl:inline-block xl:mt-0 text-white mr-4">
                        Technical Articles
                    </Link>

                    <Link to='/contact' className="block mt-4 xl:inline-block xl:mt-0 text-white mr-4">
                        Contact
                    </Link>
                </div>
            </div>
        </nav>
    )
}

export default HeaderNew