import React from 'react'
import HeaderNew from './header-new'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, siteTitle}) => (
  <>
    <HeaderNew siteTitle={siteTitle} />
      <div className={styles.content}>{children}</div>
        <footer className={styles.footer}>
          <div className={styles.footerWrapper}>
            <div className={styles.siteInfo}>
              &copy; {new Date().getFullYear()} {siteTitle}
            </div>
          </div>
        </footer>
  </>
)

export default Layout
